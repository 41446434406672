import React from "react";

const AuthContext = React.createContext({
    token:null,
    user: null,
    setUser: () => {},
    setToken: () => {},
    refreshCounter: null,
    refresh: () => {}
});

export default AuthContext;
