import React, { useState, useContext } from "react";
import CSVReader from 'react-csv-reader';
import {toast} from "react-toastify";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import Loader from "react-loader-spinner";

const GuestImporter = ({setOpen, refreshData, setRefreshData}) => {

    const parseOptions = { skipEmptyLines: true, header: true }
    const [data, setData] = useState(null);
    const [fileInfo, setFileInfo] = useState(null);
    const [buttonLoading, setButtonLoading] = useState(false);
    const { token } = useContext(AuthContext);
    const [mappedFields, setMappedFields] = useState({
        firstName: null,
        lastName: null,
        email: null,
        structure: null,
    });

    const handleFileUpload = () => {

        let formattedData = [...data];

        formattedData = formattedData.map(row => { return {
            firstName: row[mappedFields.firstName],
            lastName: row[mappedFields.lastName],
            email: row[mappedFields.email] ? row[mappedFields.email] : null,
            structure: row[mappedFields.structure],
            sendEmail: true,
            ticketsNumber: 1
        }})

        setButtonLoading(true);

        axios.post(process.env.REACT_APP_UPA_API_HOST + 'guests/import' , formattedData, { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                console.log(response);
                setButtonLoading(false);
                setOpen(false);
                setRefreshData(refreshData + 1);

                toast.success("Les invités ont bien été importés", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
            .catch((error) => {
                toast.error(error.response.data.message, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setButtonLoading(false);
            });
    }

    const handleMapFields = (data, fileInfo) => {
        setData(data);
        setFileInfo(fileInfo);
    }

    return (
        <div className="guest-importer">
            <span className='text-note'>Note : La première ligne du fichier doit contenir les noms des colonnes.</span>
            <div className='csv-file-input'>
                <label htmlFor="react-csv-reader-input" className="label-file">{ fileInfo ? fileInfo.name : 'Choisir un fichier' }</label>
                <CSVReader onFileLoaded={handleMapFields} parserOptions={parseOptions} />
            </div>
            { data ? (
                <div>
                    <span className='text-note'>Choisissez les colonnes correspondantes :</span>
                    <div className='input-row-container'>
                        <span className="input-label user">Prénom</span>
                        <select className='map-select' onChange={(e) => setMappedFields({...mappedFields, firstName: e.target.value })}>
                            <option disabled selected>Choisissez une option</option>
                            { data && Object.keys(data[0]).map((header, index) => (
                                <option key={index}>{header}</option>
                            ))}
                        </select>
                    </div>
                    <div className='input-row-container'>
                        <span className="input-label user">Nom</span>
                        <select className='map-select' onChange={(e) => setMappedFields({...mappedFields, lastName: e.target.value })}>
                            <option disabled selected>Choisissez une option</option>
                            { data && Object.keys(data[0]).map((header, index) => (
                                <option key={index}>{header}</option>
                            ))}
                        </select>
                    </div>
                    <div className='input-row-container'>
                        <span className="input-label email">Email</span>
                        <select className='map-select' onChange={(e) => setMappedFields({...mappedFields, email: e.target.value })}>
                            <option disabled selected>Choisissez une option</option>
                            { data && Object.keys(data[0]).map((header, index) => (
                                <option key={index}>{header}</option>
                            ))}
                        </select>
                    </div>
                    <div className='input-row-container'>
                        <span className="input-label role">Organisation</span>
                        <select className='map-select' onChange={(e) => setMappedFields({...mappedFields, structure: e.target.value })}>
                            <option disabled selected>Choisissez une option</option>
                            { data && Object.keys(data[0]).map((header, index) => (
                                <option key={index}>{header}</option>
                            ))}
                        </select>
                    </div>
                    <div className='flex-end'>
                        <button className='main-button blue-button download' onClick={handleFileUpload} disabled={buttonLoading} >{ buttonLoading ? <Loader type="Oval" color="#FFFFFF" height={30} width={20} /> : 'Importer' }</button>
                    </div>
                </div>
            ) : null }
        </div>
    )
}

export default GuestImporter