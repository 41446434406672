import React, {useState} from 'react';
import "../../assets/styles/Login.css";
import { Button, Form } from "react-bootstrap";
import full_logo from "../../assets/images/small-logo.png";
import {Link} from "react-router-dom";
import axios from "axios";
import Loader from "react-loader-spinner";

const PasswordForgot = () => {

    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const [loader, setLoader] = useState(false);

    const handleSubmit = () => {

        setLoader(true);

        axios.post(process.env.REACT_APP_UPA_API_HOST + "/login/forgot-password", {
            email: email,
        })
            .then((response) => {
                setError("")
                setEmail("")
                setLoader(false)
                setMessage(response.data.message)
            })
            .catch((error) => {
                setLoader(false)
                setError(error.response.data.message)
            });
    }

    return (
        <div className="main-login">
            <div className="login-background"/>
            <div className="login-container">
                <div className="login-form">
                    <div className='img-container'>
                        <img src={full_logo} alt="logo" width="300" />
                    </div>
                    <div>
                        <h1>Mot de passe oublié</h1>
                        { error ? <p className="login-error">{error}</p> : null }
                        { message ? <p className="login-message">{message}</p> : null }
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Email</Form.Label>
                            <span className='required'> *</span>
                            <Form.Control type="email" placeholder='Saisissez votre adresse email' value={email} onChange={(e) => {
                                setEmail(e.target.value)
                                setError('')
                            }} />
                        </Form.Group>
                        <Link to='/login' className='forgot-password'>
                            <p>Retour à la connexion</p>
                        </Link>
                        <div className='login-button-container'>
                            { loader ? <Button className="login-button-loading" disabled><Loader type="Oval" color="#ffffff" height={25} width={20} /></Button> : <Button className="login-button" type="submit" onClick={handleSubmit}>Envoyer</Button> }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PasswordForgot;