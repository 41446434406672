import React, {useContext, useState} from "react";
import {Link, useRouteMatch} from "react-router-dom";
import CustomList from "../../custom/CustomList";
import CustomTag from "../../custom/CustomTag";
import {toast} from "react-toastify";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";

const UserList = () => {

    let { path } = useRouteMatch();
    const { token } = useContext(AuthContext);
    const [selectedColumns, setSelectedColumns] = useState(0);
    const [selectedIds, setSelectedIds] = useState([]);
    const [refreshCounter, setRefreshCounter] = useState(0);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Prénom',
                accessor: 'firstName',
                icon: 'user'
            },
            {
                Header: 'Nom',
                accessor: 'lastName',
                icon: 'user'
            },
            {
                Header: 'Email',
                accessor: 'email',
                icon: 'email'
            },
            {
                Header: 'Rôle',
                accessor: 'role',
                icon: 'role',
                Cell: ({ cell: { value } }) => (<CustomTag value={value} />)
            },
            {
                Header: 'Statut',
                accessor: 'status',
                icon: 'role',
                Cell: ({ cell: { value } }) => (<CustomTag value={value} />)
            }
        ],
        []
    )

    const handleBulkArchive = () => {
        toast.promise(
            axios.put(process.env.REACT_APP_UPA_API_HOST + 'users/bulk-archive', { selectedIds }, { headers: { Authorization: 'Bearer ' + token }}),
            {
                pending: 'Archivage en cours',
                success: 'Utilisateurs archivés',
                error: 'Une erreur est survenue'
            }, {
                position: "bottom-right",
                autoClose: 5000,
            }
        ).then(() => {
            setRefreshCounter(refreshCounter + 1);
        });
    }

    const handleBulkDelete = () => {
        if (window.confirm("Attention : vous êtes sur le point de supprimer plusieurs utilisateurs")) {
            toast.promise(
                axios.post(process.env.REACT_APP_UPA_API_HOST + 'users/bulk-delete', { selectedIds }, { headers: { Authorization: 'Bearer ' + token }}),
                {
                    pending: 'Suppression en cours',
                    success: 'Utilisateurs supprimés',
                    error: 'Une erreur est survenue'
                }, {
                    position: "bottom-right",
                    autoClose: 5000,
                }
            ).then(() => {
                setRefreshCounter(refreshCounter + 1);
            });
        }
    }

    return (
        <div className='page-content'>
            <div className='list-header'>
                <div className='header-title users'>
                    <h1>Utilisateurs</h1>
                </div>
                <div className='header-buttons'>
                    { selectedColumns === 0 ?
                        <div>
                            <Link to={path + '/new'}>
                                <button className='main-button blue-button plus'>Créer un utilisateur</button>
                            </Link>
                        </div>
                        :
                        <div>
                            <button className='main-button archive' onClick={handleBulkArchive}>Archiver</button>
                            <button className='main-button warning-button delete' onClick={handleBulkDelete}>Supprimer</button>
                        </div>
                    }
                </div>
            </div>
            <CustomList entity='users' columns={columns} setSelectedColumns={setSelectedColumns} setSelectedIds={setSelectedIds} refreshData={refreshCounter} />
        </div>
    );
}

export default UserList;
