import React from "react";
import {Redirect, Route, Switch, useRouteMatch} from "react-router-dom";
import GuestList from "./GuestList";
import GuestAdd from "./GuestAdd";
import GuestView from "./GuestView";
import GuestEdit from "./GuestEdit";

const Guests = () => {

    let { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path}>
                <GuestList />
            </Route>
            <Route path={path + '/new'}>
                <GuestAdd />
            </Route>
            <Route path={path + "/:guestId/edit"}>
                <GuestEdit />
            </Route>
            <Route path={path + "/:guestId"}>
                <GuestView />
            </Route>
            <Route path={ path + '*'}>
                <Redirect to={path} />
            </Route>
        </Switch>
    );
}

export default Guests;
