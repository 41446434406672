import React, {useContext, useState} from "react";
import {Link, Route, Switch, useRouteMatch} from "react-router-dom";
import mainLogo from "../../assets/images/small-logo.png";
import Loader from "react-loader-spinner";
import AuthContext from "../../contexts/AuthContext";
import Dashboard from "./dashboard/Dashboard";
import '../../assets/styles/AdminPanel.css';
import Customers from "./customers/Customers";
import Settings from "./settings/Settings";
import Tippy from '@tippyjs/react';
import Users from "./users/Users";
import Guests from "./guests/Guests";
import CustomSearchBar from "../custom/CustomSearchBar";
import Scanner from "./scanner/Scanner";
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import {IconButton} from "@mui/material";

const AdminPanel = () => {

    const { user, setToken, setUser } = useContext(AuthContext);
    const [popoverVisible, setPopoverVisible] = useState(false);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const show = () => setPopoverVisible(true);
    const hide = () => setPopoverVisible(false);
    let { path } = useRouteMatch();

    const loader = (
        <Loader type="Oval" color="#2C95E8" height={25} width={20} />
    );

    const handleDisconnect = () => {
        localStorage.clear();
        setToken(null);
        setUser(null);
    }

    const popover = (
        <div className='user-options'>
            { user && user.role !== "scanner" ? (
                <div>
                    <Link to={path + '/settings'} className='menu-link' onClick={popoverVisible ? hide : show}>
                        <div className='user-options-item'>
                            <span>Paramètres</span>
                        </div>
                    </Link>
                    <Link to={ path + '/users'} className='menu-link' onClick={popoverVisible ? hide : show}>
                        <div className='user-options-item users' >
                            <span>Utilisateurs</span>
                        </div>
                    </Link>
                </div>
            ) : null }
            <div className='user-options-item logout' onClick={handleDisconnect}>
                <span>Se déconnecter</span>
            </div>
        </div>
    );

    return user ? (
        <div className='main-app'>
            <div className={ mobileMenuOpen ? 'main-menu open' : 'main-menu'}>
                <div className='app-logo'>
                    <Link to='/admin'>
                        <img src={mainLogo} alt='main-logo' width='180'/>
                    </Link>
                </div>
                <div className='menu-container'>
                    <div className='relative'>
                        <Link to={ path } className='menu-link' onClick={() => setMobileMenuOpen(false)}>
                            <div className={'menu-item stadium' + (window.location.href === process.env.REACT_APP_UPA_INTERFACE_HOST + 'admin' ? ' active' : '')} onClick={() => setMobileMenuOpen(false)}>
                                <span>Stade</span>
                            </div>
                        </Link>
                    </div>
                    { user.role !== "scanner" ? (
                        <div>
                            <div className='relative' onClick={() => setMobileMenuOpen(false)}>
                                <Link to={ path + '/customers'} className='menu-link'>
                                    <div className={'menu-item subscriptions' + (window.location.href.replace(process.env.REACT_APP_UPA_INTERFACE_HOST, '').includes('customers') ? ' active' : '')}>
                                        <span>Public</span>
                                    </div>
                                </Link>
                                <Link to={ path + '/customers/new'}>
                                    <span className='new-button'>+</span>
                                </Link>
                            </div>
                            <div className='relative'>
                                <Link to={ path + '/guests'} className='menu-link' onClick={() => setMobileMenuOpen(false)}>
                                    <div className={'menu-item subscriptions' + (window.location.href.replace(process.env.REACT_APP_UPA_INTERFACE_HOST, '').includes('guests') ? ' active' : '')} onClick={() => setMobileMenuOpen(false)}>
                                        <span>Invités</span>
                                    </div>
                                </Link>
                                <Link to={ path + '/guests/new'}>
                                    <span className='new-button'>+</span>
                                </Link>
                            </div>
                        </div>
                    ) : null }
                    <div className='relative'>
                        <Link to={ path + '/scanner'} className='menu-link' onClick={() => setMobileMenuOpen(false)}>
                            <div className={'menu-item scanner' + (window.location.href.replace(process.env.REACT_APP_UPA_INTERFACE_HOST, '').includes('scanner') ? ' active' : '')} onClick={() => setMobileMenuOpen(false)} >
                                <span>Scanner</span>
                            </div>
                        </Link>
                    </div>
                    { user.role !== "scanner" ? (
                        <div>
                            <div className='relative mobile-hidden'>
                                <Link to={ path + '/settings'} className='menu-link' onClick={() => setMobileMenuOpen(false)}>
                                    <div className={'menu-item settings' + (window.location.href.replace(process.env.REACT_APP_UPA_INTERFACE_HOST, '').includes('settings') ? ' active' : '')} onClick={() => setMobileMenuOpen(false)} >
                                        <span>Paramètres</span>
                                    </div>
                                </Link>
                            </div>
                            <div className='relative mobile-hidden'>
                                <Link to={ path + '/users'} className='menu-link' onClick={() => setMobileMenuOpen(false)}>
                                    <div className={'menu-item users' + (window.location.href.replace(process.env.REACT_APP_UPA_INTERFACE_HOST, '').includes('users') ? ' active' : '')} onClick={() => setMobileMenuOpen(false)} >
                                        <span>Utilisateurs</span>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    ) : null }
                    <div className='relative mobile-hidden'>
                        <div className={'menu-item logout'} onClick={handleDisconnect} >
                            <span>Déconnexion</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='main-app-right-part'>
                <div className='mobile-navbar'>
                    <div className='app-logo'>
                        <Link to='/admin'>
                            <img src={mainLogo} alt='main-logo' width='150'/>
                        </Link>
                    </div>
                    <div className='burger-menu-container'>
                        <IconButton aria-label="Menu" onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                            <MenuRoundedIcon />
                        </IconButton>
                    </div>
                </div>
                <div className='main-navbar'>
                    <div className='searchbar-container'>
                        { user.role !== "scanner" && window.innerWidth > 981 ? (
                            <CustomSearchBar />
                        ) : null }
                    </div>
                    <div className='user-info-container'>
                        <Tippy content={popover}
                               allowHTML={true}
                               placement={'bottom-end'}
                               offset={[0,0]}
                               visible={popoverVisible}
                               onClickOutside={hide}
                               interactive={true}
                               appendTo={'parent'}>
                            <div className='user-item' onClick={popoverVisible ? hide : show}>
                                <span>{ user ? user.firstName + ' ' + user.lastName : loader}</span>
                            </div>
                        </Tippy>
                    </div>
                </div>
                <div className='main-router-content'>
                    <Switch>
                        <Route path={ path + '/scanner'}>
                            <Scanner />
                        </Route>
                        { user.role !== "scanner" ? (
                            <Route path={ path + '/customers'}>
                                <Customers />
                            </Route>
                        ) : null }
                        { user.role !== "scanner" ? (
                            <Route path={ path + '/guests'}>
                                <Guests />
                            </Route>
                        ) : null }
                        { user.role !== "scanner" ? (
                            <Route path={ path + '/settings'}>
                                <Settings />
                            </Route>
                        ) : null }
                        { user.role !== "scanner" ? (
                            <Route path={ path + '/users'}>
                                <Users />
                            </Route>
                        ) : null }
                        <Route path='/'>
                            <Dashboard />
                        </Route>
                    </Switch>
                </div>
            </div>
        </div>
    ) : (
        <div className='loader-container'>
            <Loader type="Oval" color="#2C95E8" height={30} width={30} />
        </div>
    );
}

export default AdminPanel;
