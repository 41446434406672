import React, {useContext, useState} from 'react';
import {useHistory, useParams } from "react-router-dom";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import CustomTag from "../../custom/CustomTag";
import Tippy from "@tippyjs/react";
import dayjs from "dayjs";
import { Document, Page } from "react-pdf/dist/umd/entry.webpack";
import { saveAs } from "file-saver";
import Switch from '@mui/material/Switch';

const CustomerView = () => {

    const { customerId } = useParams();
    const [customer, setCustomer] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const { token } = useContext(AuthContext);
    const history = useHistory();
    const [popoverVisible, setPopoverVisible] = useState(false);
    const show = () => setPopoverVisible(true);
    const hide = () => setPopoverVisible(false);

    React.useEffect(() => {
        axios.get(process.env.REACT_APP_UPA_API_HOST + 'customers/' + customerId, { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                if(response.data) {
                    console.log(response.data);
                    setCustomer(response.data);
                    setIsLoaded(true);
                } else {
                    toast.error('Billet inconnu', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    history.goBack();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [history, token, customerId]);

    const handleDelete = () => {
        if (window.confirm("Attention : Ce billet ne sera plus valide")) {
            toast.promise(
                axios.delete(process.env.REACT_APP_UPA_API_HOST + 'customers/' + customerId, { headers: { Authorization: 'Bearer ' + token }}),
                {
                    pending: 'Suppression en cours',
                    success: 'Billet supprimé',
                    error: 'Une erreur est survenue'
                }, {
                    position: "bottom-right",
                    autoClose: 5000,
                }
            ).then(() => {
                history.goBack();
            });
        }
    }

    const popover = isLoaded ? (
        <div className='user-options' onClick={popoverVisible ? hide : show}>
            <div className='user-options-item download' onClick={() => saveAs(process.env.REACT_APP_UPA_API_HOST + 'files/' + customer.id + '.pdf', "Billet_" + customer.lastName + ".pdf")}>
                <span>Télécharger le billet</span>
            </div>
            <div className='user-options-item delete' onClick={handleDelete}>
                <span>Supprimer</span>
            </div>
        </div>
    ) : null;

    return isLoaded ? (
        <div className='view-container'>
            <div className='view-header'>
                <div className='view-title'>
                    <h1>{customer.firstName + ' ' + customer.lastName + ' - Billet ' + customer.ticketNumber}</h1>
                    <span className='view-id'>{customer.id}</span>
                </div>
                <div className='view-buttons'>
                    <button className='main-button return-button' onClick={() => history.goBack()} />
                    <Tippy content={popover}
                           allowHTML={true}
                           placement={'bottom-end'}
                           offset={[0,5]}
                           visible={popoverVisible}
                           onClickOutside={hide}
                           interactive={true}
                           appendTo={'parent'}>
                        <button className='squared-button margin-left' onClick={popoverVisible ? hide : show} />
                    </Tippy>
                </div>
            </div>
            <div className='input-row-container'>
                <span className="input-label email">Email</span>
                <CustomTag value={customer.email} />
            </div>
            <div className='input-row-container'>
                <span className="input-label location">Code postal</span>
                <CustomTag value={customer.postalCode} />
            </div>
            <div className='input-row-container'>
                <span className="input-label calendar">Créé le</span>
                <CustomTag value={dayjs(customer.createdAt).format('DD MMMM YYYY à HH:MM')} />
            </div>
            <div className='input-row-container'>
                <span className="input-label user">Billet collaboratif</span>
                <Switch checked={customer.isCommon} />
            </div>
            <span className='subsection-title'>Aperçu du billet :</span>
            <Document file={process.env.REACT_APP_UPA_API_HOST + 'files/' + customer.id + '.pdf'} >
                <Page pageNumber={1} />
            </Document>
        </div>
    ) : (<div className='loader-container'><Loader type="Oval" color="#2C95E8" height={30} width={30} /></div>)
}

export default CustomerView;