import React from "react";
import {Redirect, Route, Switch, useRouteMatch} from "react-router-dom";
import UserList from "./UserList";
import UserAdd from "./UserAdd";
import UserView from "./UserView";
import UserEdit from "./UserEdit";

const Users = () => {

    let { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path}>
                <UserList />
            </Route>
            <Route path={path + '/new'}>
                <UserAdd />
            </Route>
            <Route path={path + "/:userId/edit"}>
                <UserEdit />
            </Route>
            <Route path={path + "/:userId"}>
                <UserView />
            </Route>
            <Route path={ path + '*'}>
                <Redirect to={path} />
            </Route>
        </Switch>
    );
}

export default Users;
