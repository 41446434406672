import { QrReader } from 'react-qr-reader'
import React, {useContext, useState} from "react";
import axios from "axios";
import { validate as uuidValidate } from 'uuid';
import AuthContext from "../../../contexts/AuthContext";
import Loader from "react-loader-spinner";
import {toast} from "react-toastify";
import valid_logo from "../../../assets/images/accept.png";
import invalid_logo from "../../../assets/images/decline.png";
import CustomTag from "../../custom/CustomTag";
import dayjs from 'dayjs';

const Scanner = () => {

    const [scanData, setScanData] = useState(null);
    const [scan, setScan] = useState(null);
    const [isScanned, setIsScanned] = useState(false);
    const { user, token, refresh, refreshCounter } = useContext(AuthContext);
    const [attendance, setAttendance] = useState(null);
    const [dataRefresh, setDataRefresh] = useState(0);

    React.useEffect(() => {
        const timer = setTimeout(() => {
            setDataRefresh(dataRefresh + 1);
        }, 5000);
        return () => clearTimeout(timer);
    }, [dataRefresh]);

    React.useEffect(() => {
        axios.get(process.env.REACT_APP_UPA_API_HOST + 'data/attendance', { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                console.log(response.data);
                setAttendance(response.data);
            })
            .catch((error) => {
                toast.error(error.response.data.message, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
    }, [dataRefresh]);

    const handleChooseGate = (gate) => {
        axios.put(process.env.REACT_APP_UPA_API_HOST + 'users/' + user.id , { user : {...user, gate: gate } }, { headers: { Authorization: 'Bearer ' + token }})
            .then(() => {
                refresh(refreshCounter + 1);
            })
            .catch((error) => {
                toast.error(error.response.data.message, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
    }

    const handleScan = (data) => {
        if (data && !isScanned) {
            setScan(data.text);
            if (data.text && uuidValidate(data.text)) {
                axios.post(process.env.REACT_APP_UPA_API_HOST + 'stadium/validate', {
                    id: data.text,
                    gate: user.gate
                }, {headers: {"Authorization": "Bearer " + token}})
                    .then((response) => {
                        console.log(response.data);
                        setScanData(response.data);
                        setIsScanned(true);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        }
    }

    const handleError = (err) => {
        console.error(err)
    }

    const handleEntry = (userId) => {
        axios.post(process.env.REACT_APP_UPA_API_HOST + 'stadium', {
            userId: userId,
            scannerId: user.id,
            gate: user.gate
        } ,{
            headers:{ "Authorization": "Bearer " + token }
        })
            .then(() => {
                toast.success("Entrée effectuée", {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setAttendance({
                    ...attendance,
                    guestsAttendance: user.gate === 'B' ? attendance.guestsAttendance : (attendance.guestsAttendance + 1),
                    customersAttendance: user.gate === 'A' ? attendance.customersAttendance : (attendance.customersAttendance + 1)
                });
                resetScanner();
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleManualEntry = (userType, entryType) => {
        switch (userType) {
            case 'A':
                userType = 'guests';
                break;
            case 'B':
                userType = 'customers';
                break;
        }
        axios.post(process.env.REACT_APP_UPA_API_HOST + 'stadium/manual-entry', {
            userType: userType,
            entryType: entryType
        } ,{
            headers:{ "Authorization": "Bearer " + token }
        })
            .then(() => {
                setAttendance({
                    ...attendance,
                    guestsAttendance: user.gate === 'B' ? attendance.guestsAttendance : (entryType === "entry" ? (attendance.guestsAttendance + 1) : (attendance.guestsAttendance - 1)),
                    customersAttendance: user.gate === 'A' ? attendance.customersAttendance : (entryType === "entry" ? (attendance.customersAttendance + 1) : (attendance.customersAttendance - 1))
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleExit = (userId) => {
        axios.post(process.env.REACT_APP_UPA_API_HOST + 'stadium/exit', {
            userId: userId,
            scannerId: user.id,
            gate: user.gate
        } ,{
            headers:{ "Authorization": "Bearer " + token }
        })
            .then((response) => {
                console.log(response);
                toast.success("Sortie effectuée", {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setAttendance({
                    ...attendance,
                    guestsAttendance: user.gate === 'B' ? attendance.guestsAttendance : (attendance.guestsAttendance - 1),
                    customersAttendance: user.gate === 'A' ? attendance.customersAttendance : (attendance.customersAttendance - 1)
                });
                resetScanner();
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const resetScanner = () => {
        setIsScanned(false);
        setScanData(null);
        setScan(null);
    }

    const ScannerAim = () => (
        <div className='scanner-aim' />
    )

    return user.gate ? (
        !scan ? (
            <div>
                <div className='gate-info'>
                    <span>Vous êtes à la porte {user.gate}</span>
                    <span className='change-gate' onClick={() => handleChooseGate(null)}>Changer</span>
                </div>
                <div className='scanner-container'>
                    <QrReader
                        delay={300}
                        onError={handleError}
                        onResult={handleScan}
                        videoContainerStyle={{ paddingTop: 0 }}
                        videoStyle={{ top: 0 , left: '50%', height: '100%', position: 'absolute', maxHeight: '350px', zIndex: -1 }}
                        constraints={{ facingMode: 'environment' }}
                        ViewFinder={ScannerAim}
                    />
                </div>
                <div className='manual-entry-container'>
                    <button className='exit' onClick={() => handleManualEntry(user.gate, 'exit')}>-</button>
                    <span>Entrée manuelle</span>
                    <button className='entry' onClick={() => handleManualEntry(user.gate, 'entry')}>+</button>
                </div>
                <div className='scanner-stadium-info'>
                    <span>Fréquentation</span>
                    { attendance ? (
                        <div>
                            <span className='attendance'>{user.gate === "A" ? attendance.guestsAttendance : attendance.customersAttendance}</span>
                            <span>/{user.gate === "A" ? attendance.guestsMaxAttendance : attendance.customersMaxAttendance}</span>
                        </div>
                    ) : (
                        <Loader type="Oval" color="#fff" height={20} width={20} />
                    )}
                </div>
            </div>
        ) : (
            isScanned ? (
                scanData && scanData.gate === user.gate ? (
                    <div className='scanned-container'>
                        <div className='logo-scanner-container'>
                            <img alt='logo' src={valid_logo} width={100}/>
                        </div>
                        <div className='scanned-info'>
                            <CustomTag value={scanData.userType} className=' margin-bottom-15' />
                            <CustomTag value={scanData.firstName + ' ' + scanData.lastName} className=' margin-bottom-15' />
                            { scanData.isCommon ? (
                                <div className='scanner-actions'>
                                    <button onClick={resetScanner}>Annuler</button>
                                    <button className='exit margin-left' onClick={() => handleExit(scanData.id)}>Sortie</button>
                                    <button className='entry margin-left' onClick={() => handleEntry(scanData.id)}>Entrée</button>
                                </div>
                            ) : scanData.entries.length > 0 && scanData.entries[0].type === 'entry' ? (
                                <div className='full-width'>
                                    <CustomTag value={"Entrée à " + dayjs(scanData.entries[0].date).format('HH:mm')} className=' margin-bottom-15' />
                                    <div className='scanner-actions'>
                                        <button onClick={resetScanner}>Annuler</button>
                                        <button className='exit margin-left' onClick={() => handleExit(scanData.id)}>Sortie</button>
                                    </div>
                                </div>
                            ) : (
                                <div className='scanner-actions'>
                                    <button onClick={resetScanner}>Annuler</button>
                                    <button className='entry margin-left' onClick={() => handleEntry(scanData.id)}>Entrée</button>
                                </div>
                            )}
                        </div>
                        { scanData.entries.length > 0 ? (
                            <div className='scan-history'>
                                <strong>Historique</strong>
                                { scanData.entries.map((entry, index) => (
                                    <div className={'history-block ' + entry.type} key={index}>
                                        <span>{dayjs(entry.date).format('HH:mm:ss')}</span>
                                        <span>Scanné par {entry.scanner.firstName + ' ' + entry.scanner.lastName}</span>
                                    </div>
                                ))}
                            </div>
                        ) : null }
                    </div>
                ) : (
                    <div className='scanned-container'>
                        <div className='logo-scanner-container'>
                            <img alt='logo' src={invalid_logo} width={100}/>
                        </div>
                        <span className='warning'>{ scanData ? 'Mauvaise porte, merci de vous diriger vers la porte ' + scanData.gate : 'Billet inconnu'}</span>
                        <div className='scanned-info'>
                            <div className='scanner-actions'>
                                <button onClick={resetScanner} className='entry'>Nouveau scan</button>
                            </div>
                        </div>
                    </div>
                )
            ) : (
                <div className='loader-container'><Loader type="Oval" color="#2C95E8" height={30} width={30} /></div>
            )
        )
    ) : (
        <div className='gate-chooser-container'>
            <div className='list-header'>
                <div className='header-title scanner'>
                    <h1>Choix de la porte</h1>
                </div>
            </div>
            <span className='text-note'>Choisissez la porte à laquelle vous êtes situé pour commencer à scanner des billets.</span>
            <div className='gates-container'>
                <div className='main-data' onClick={() => handleChooseGate('A')}>
                    <h1>Porte A</h1>
                    <span>Invités</span>
                </div>
                <div className='main-data' onClick={() => handleChooseGate('B')}>
                    <h1>Porte B</h1>
                    <span>Grand public</span>
                </div>
            </div>
        </div>
    )
}

export default Scanner