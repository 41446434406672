import React from "react";
import {Redirect, Route, Switch, useRouteMatch} from "react-router-dom";
import CustomerList from "./CustomerList";
import CustomerAdd from "./CustomerAdd";
import CustomerView from "./CustomerView";
import CustomerEdit from "./CustomerEdit";

const Customers = () => {

    let { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path}>
                <CustomerList />
            </Route>
            <Route path={path + '/new'}>
                <CustomerAdd />
            </Route>
            <Route path={path + "/:customerId/edit"}>
                <CustomerEdit />
            </Route>
            <Route path={path + "/:customerId"}>
                <CustomerView />
            </Route>
            <Route path={ path + '*'}>
                <Redirect to={path} />
            </Route>
        </Switch>
    );
}

export default Customers;
