import React, {useContext, useState} from "react";
import {Link, useRouteMatch} from "react-router-dom";
import CustomList from "../../custom/CustomList";
import {toast} from "react-toastify";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import dayjs from "dayjs";
import Loader from "react-loader-spinner";

const CustomerList = () => {

    let { path } = useRouteMatch();
    const [selectedColumns, setSelectedColumns] = useState(0);
    const [selectedIds, setSelectedIds] = useState([]);
    const [refreshCounter, setRefreshCounter] = useState(0);
    const [data, setData] = useState(null);
    const { token } = useContext(AuthContext);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Prénom',
                accessor: 'firstName',
                icon: 'user'
            },
            {
                Header: 'Nom',
                accessor: 'lastName',
                icon: 'user'
            },
            {
                Header: 'Email',
                accessor: 'email',
                icon: 'email'
            },
            {
                Header: 'Code postal',
                accessor: 'postalCode',
                icon: 'order',
                mobileHidden: true
            },
            {
                Header: 'Billet n°',
                accessor: 'ticketNumber',
                icon: 'id',
                mobileHidden: true
            },
            {
                Header: 'Inscrit le',
                accessor: 'createdAt',
                icon: 'calendar',
                Cell: ({ cell: { value } }) => (<span>{ dayjs(value).format('DD/MM/YYYY à HH:mm') }</span>),
                mobileHidden: true
            }
        ],
        []
    )

    const handleBulkDelete = () => {
        if (window.confirm("Attention : vous êtes sur le point de supprimer plusieurs billets")) {
            toast.promise(
                axios.post(process.env.REACT_APP_UPA_API_HOST + 'customers/bulk-delete', { selectedIds }, { headers: { Authorization: 'Bearer ' + token }}),
                {
                    pending: 'Suppression en cours',
                    success: 'Billets supprimés',
                    error: 'Une erreur est survenue'
                }, {
                    position: "bottom-right",
                    autoClose: 5000,
                }
            ).then(() => {
                setRefreshCounter(refreshCounter + 1);
            });
        }
    }

    return (
        <div className='page-content'>
            <div className='list-header'>
                <div className='header-title flex-title subscriptions'>
                    <h1>Billets public</h1>
                    <span className='custom-badge margin-left'>{data ? data.length : (<Loader type="Oval" color="#fff" height={10} width={10} />)}</span>
                </div>
                { selectedColumns === 0 ?
                    <div className='header-buttons'>
                        <Link to={path + '/new'}>
                            <button className='main-button blue-button plus'>Ajouter</button>
                        </Link>
                    </div>
                    :
                    <div className='header-buttons'>
                        <button className='main-button warning-button delete' onClick={handleBulkDelete}>Supprimer</button>
                    </div>
                }
            </div>
            <CustomList entity='customers' columns={columns} setSelectedColumns={setSelectedColumns} setSelectedIds={setSelectedIds} refreshData={refreshCounter} setData={setData}  />
        </div>
    );
}

export default CustomerList;
