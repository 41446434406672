import React, {useState, useContext} from "react";
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, ReferenceLine } from 'recharts';
import {Grid, LinearProgress} from "@mui/material";
import scanner_icon from "../../../assets/icons/scanner.svg";
import axios from "axios";
import {toast} from "react-toastify";
import AuthContext from "../../../contexts/AuthContext";
import Loader from "react-loader-spinner";

const Dashboard = () => {

    const [data, setData] = useState();
    const [refresh, setRefresh] = useState(0);
    const { token } = useContext(AuthContext);

    React.useEffect(() => {
        const timer = setTimeout(() => {
            setRefresh(refresh + 1);
        }, 5000);
        return () => clearTimeout(timer);
    }, [refresh]);

    React.useEffect(() => {
        axios.get(process.env.REACT_APP_UPA_API_HOST + 'data/dashboard', { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                if(response.data) {
                    setData(response.data);
                } else {
                    toast.error('Une erreur est survenue', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [refresh])

    return data ? (
        <div className='page-content'>
            <div className='dashboard-container'>
                <div className='left-side'>
                    <div className='list-header'>
                        <div className='header-title subscriptions'>
                            <h1>Les billets</h1>
                        </div>
                    </div>
                    <Grid container spacing={2}>
                        <Grid item sm={6}>
                            <div className='main-data'>
                                <h1>{data.customerCount}</h1>
                                <span>Public</span>
                            </div>
                        </Grid>
                        <Grid item sm={6}>
                            <div className='main-data'>
                                <h1>{data.guestCount}</h1>
                                <span>Invités</span>
                            </div>
                        </Grid>
                    </Grid>
                    <div className='list-header padding-top'>
                        <div className='header-title stadium'>
                            <h1>Le stade</h1>
                        </div>
                    </div>
                    <div className='fmi-container'>
                        <div className='fmi-block'>
                            <div className='fmi-info'>
                                <span>Public</span>
                                <div>
                                    <span>{data.stadiumCustomers}</span>
                                    <span> / {data.customersAttendance}</span>
                                </div>
                            </div>
                            <LinearProgress variant="determinate" value={(data.stadiumCustomers / data.customersAttendance) * 100} />
                        </div>
                        <div className='fmi-block'>
                            <div className='fmi-info'>
                                <span>Invités</span>
                                <div>
                                    <span>{data.stadiumGuests}</span>
                                    <span> / {data.guestsAttendance}</span>
                                </div>
                            </div>
                            <LinearProgress variant="determinate" value={(data.stadiumGuests / data.guestsAttendance) * 100} />
                        </div>
                    </div>
                    <div className='dashboard-chart-container'>
                        <ResponsiveContainer>
                            <LineChart width={500} height={300} data={data.chartData}>
                                <Line strokeWidth={3} type="monotone" dataKey="customersAttendance" stroke="#2c95e8" dot={false} />
                                <Line strokeWidth={3} type="monotone" dataKey="guestsAttendance" stroke="#2FB163" dot={false} />
                                <Line strokeWidth={3} type="monotone" dataKey="totalAttendance" stroke="#7147C2" dot={false} />
                                <ReferenceLine y={ data.guestsAttendance + data.customersAttendance } label="FMI totale" stroke="#7147C2" strokeDasharray="3 3" />
                                <ReferenceLine y={ data.customersAttendance } label="FMI public" stroke="#7147C2" strokeDasharray="3 3" />
                                <ReferenceLine y={ data.guestsAttendance } label="FMI invités" stroke="#2FB163" strokeDasharray="3 3" />
                                <XAxis dataKey="createdAt" />
                                <YAxis domain={[0, 4500]} />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                <div className='right-side'>
                    <div className='active-scanners-container'>
                        <div className='active-scanners-title'>
                            <img src={scanner_icon} width={26} alt='scanner'/>
                            <span>Scanners actifs</span>
                        </div>
                        <div className='active-scanners-list'>
                            { data.scanners && data.scanners.map((scanner, index) => (
                                <div className={scanner.gate ? 'active-scanners-block active' : 'active-scanners-block inactive' } key={index}>
                                    <div>
                                        <span className='scanner-name'>{scanner.firstName + ' ' + scanner.lastName}</span>
                                        <span className='scanner-gate'>{scanner.gate ? 'Porte ' + scanner.gate : 'Déconnecté'}</span>
                                    </div>
                                    <span className='total-scans'>{scanner.scansCount}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : (<div className='loader-container'><Loader type="Oval" color="#2C95E8" height={30} width={30} /></div>);
}

export default Dashboard;
