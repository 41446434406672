import React, {useContext, useState} from 'react';
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import { useForm } from "react-hook-form";

const GuestEdit = () => {

    const { poolId } = useParams();
    const [pool, setPool] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const { token } = useContext(AuthContext);
    const history = useHistory();
    const { register, handleSubmit, formState: { errors } } = useForm();

    React.useEffect(() => {
        axios.get(process.env.REACT_APP_UPA_API_HOST + 'pools/' + poolId, { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                if(response.data.length > 0) {
                    console.log(response.data[0]);
                    setPool(response.data[0]);
                    setIsLoaded(true);
                } else {
                    toast.error('Piscine inconnue', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    history.goBack();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [history, token, poolId]);

    const handleUpdate = () => {
        setButtonLoading(true);
        axios.put(process.env.REACT_APP_UPA_API_HOST + 'pools/' + poolId, { pool }, { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                console.log(response);
                setButtonLoading(false);
                toast.success("La piscine a bien été modifiée", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                history.goBack();
            })
            .catch((error) => {
                toast.error(error.message, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setButtonLoading(false);
            });
    }

    return isLoaded ? (
        <div className='view-container'>
            <div className='view-header'>
                <div className='header-input'>
                    <input {...register("poolName", { required: true })} className={(errors.poolName ? 'name-input-required' : '')} type='text' placeholder='Nom de la piscine' value={pool.name} onChange={(e) => {setPool((pool) => { return {...pool, name:e.target.value}})}} />
                </div>
                <div className='view-buttons'>
                    <button onClick={history.goBack} className='main-button without-icon warning-button'>Annuler</button>
                    <button className='main-button blue-button validate margin-left' onClick={handleSubmit(handleUpdate)} disabled={buttonLoading}>{ buttonLoading ? <Loader type="Oval" color="#FFFFFF" height={30} width={20} /> : 'Sauvegarder' }</button>
                </div>
            </div>
            <div className='input-row-container'>
                <span className="input-label location">Adresse</span>
                <div className='input-container without-icon'>
                    <input value={pool.address} className="classic-input" placeholder='Entrez une adresse' onChange={(e) => {
                        setPool((pool) => { return { ...pool, address:e.target.value }})
                    }}/>
                </div>
            </div>
            <div className='input-row-container'>
                <span className="input-label hidden-label" />
                <div className='input-container without-icon'>
                    <input value={pool.postalCode} className="classic-input margin-right" placeholder='Code postal' onChange={(e) => {
                        setPool((pool) => { return { ...pool, postalCode:e.target.value }})
                    }}/>
                    <input value={pool.city} className="classic-input" placeholder='Ville' onChange={(e) => {
                        setPool((pool) => { return { ...pool, city:e.target.value }})
                    }}/>
                </div>
            </div>
            <div className='input-row-container'>
                <span className="input-label phone">Téléphone</span>
                <div className='input-container without-icon'>
                    <input value={pool.phone} className={'classic-input '} placeholder='Entrez un téléphone' onChange={(e) => {
                        setPool((pool) => { return { ...pool, phone:e.target.value }})
                    }}/>
                </div>
            </div>
            <div className='input-row-container'>
                <span className="input-label email">Email</span>
                <div className='input-container without-icon'>
                    <input value={pool.email} className={'classic-input '} placeholder='Entrez un email' onChange={(e) => {
                        setPool((pool) => { return { ...pool, email:e.target.value }})
                    }}/>
                </div>
            </div>
        </div>
    ) : (<div className='loader-container'><Loader type="Oval" color="#2C95E8" height={30} width={30} /></div>)
}

export default GuestEdit;