import React from 'react';

const CustomModal = ({title, children, show, setShow}) => {

    return (
        <div className={'custom-modal ' + (show ? '' : 'hidden') }>
            <div className='custom-modal-container'>
                <div className='custom-modal-header'>
                    <h1>{title ? title : 'Modal'}</h1>
                    <button className='modal-close' onClick={() => setShow(false)}>+</button>
                </div>
                <div className='custom-modal-content'>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default CustomModal;