import {Switch, Route, Redirect } from "react-router-dom";
import Login from "./components/login/Login";
import AuthContext from "./contexts/AuthContext";
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import React, { useState } from "react";
import './assets/styles/App.css';
import axios from "axios";
import AdminPanel from "./components/admin/AdminPanel";
import PasswordForgot from "./components/login/PasswordForgot";
import PrivateRoute from "./components/login/PrivateRoute";
import {CircularProgress} from "@mui/material";
import AddScanner from "./components/login/AddScanner";

const App = () => {

    const [token, setToken] = useState(localStorage.getItem('session_token'));
    const [user, setUser] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [refresh, setRefresh] = useState(0);

    const contextValue = {
        token: token,
        user:user,
        refreshCounter: refresh,
        refresh: setRefresh,
        setToken: setToken,
        setUser:setUser
    }

    React.useEffect( () => {
        if(localStorage.getItem('session_token')) {
            axios.get(process.env.REACT_APP_UPA_API_HOST + 'users/me', {
                headers:{ "Authorization": "Bearer " + localStorage.getItem('session_token') }
            })
                .then((response) => {
                    console.log(response.data);
                    setUser(response.data);
                    setIsLoaded(true);
                })
                .catch((error) => {
                    console.log(error);
                    localStorage.clear();
                    setIsLoaded(true);
                });
        } else {
            setIsLoaded(true);
        }
    }, [token, refresh]);

    return isLoaded ? (
        <AuthContext.Provider value={contextValue}>
            <Switch>
                <PrivateRoute path="/admin">
                    <AdminPanel />
                </PrivateRoute>
                <Route exact path="/login">
                    <Login />
                </Route>
                <Route exact path="/forgot-password">
                    <PasswordForgot />
                </Route>
                <Route path="/add-scanner">
                    <AddScanner />
                </Route>
                <Route path="/">
                    <Redirect to="/admin" />
                </Route>
            </Switch>
        </AuthContext.Provider>
    ) : <div className='loader-container'><CircularProgress size={30} /></div>;
}

export default App;
