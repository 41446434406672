import React, {useContext, useState} from 'react';
import { useHistory } from "react-router-dom";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";

const CustomerAdd = () => {

    const [customer, setCustomer] = useState({ firstName: '', lastName: '', email: '', postalCode: '', ticketsNumber: 1 });
    const [buttonLoading, setButtonLoading] = useState(false);
    const { token } = useContext(AuthContext);
    const history = useHistory();

    React.useEffect(() => {
        console.log(customer);
    }, [customer]);

    const handleCreate = () => {
        setButtonLoading(true);
        axios.post(process.env.REACT_APP_UPA_API_HOST + 'customers' , customer, { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                console.log(response);
                setButtonLoading(false);
                toast.success("Les billets ont bien été ajoutés", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                history.goBack();
            })
            .catch((error) => {
                toast.error(error.response.data.message, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setButtonLoading(false);
            });
    }

    return (
        <div>
            <div className='list-header'>
                <div className='view-title'>
                    <h1>Ajouter des billets</h1>
                </div>
                <div className='view-buttons'>
                    <button className='main-button blue-button margin-right' onClick={handleCreate} disabled={buttonLoading}>{ buttonLoading ? <Loader type="Oval" color="#FFFFFF" height={30} width={20} /> : 'Ajouter les billets' }</button>
                    <button onClick={history.goBack} className='main-button without-icon warning-button'>Annuler</button>
                </div>
            </div>
            <div className='input-group without-top'>
                <div className='input-row-container'>
                    <span className="input-label user">Nom</span>
                    <div className='input-container without-icon'>
                        <input value={customer.firstName} className="classic-input margin-right" placeholder='Prénom' onChange={(e) => {
                            setCustomer({ ...customer, firstName:e.target.value })
                        }}/>
                        <input value={customer.lastName} className="classic-input" placeholder='Nom' onChange={(e) => {
                            setCustomer({ ...customer, lastName:e.target.value })
                        }}/>
                    </div>
                </div>
                <div className='input-row-container'>
                    <span className="input-label email">Email</span>
                    <div className='input-container without-icon'>
                        <input value={customer.email} className={'classic-input'} placeholder='Entrez un email' onChange={(e) => {
                            setCustomer({ ...customer, email:e.target.value })
                        }}/>
                    </div>
                </div>
                <div className='input-row-container'>
                    <span className="input-label location">Code postal</span>
                    <div className='input-container without-icon'>
                        <input value={customer.postalCode} className={'classic-input'} placeholder='Entrez un code postal' onChange={(e) => {
                            setCustomer({ ...customer, postalCode:e.target.value })
                        }}/>
                    </div>
                </div>
                <div className='input-row-container'>
                    <span className="input-label role">Nombre de billets</span>
                    <div className='input-container without-icon'>
                        <input type='number' min={1} max={6} value={customer.ticketsNumber} className={'classic-input'} placeholder='Entrez un nombre de billets' onChange={(e) => {
                            setCustomer({ ...customer, ticketsNumber: parseInt(e.target.value) })
                        }}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomerAdd;