import React from "react";
import CustomTag from "./CustomTag";
import {useHistory} from "react-router-dom";

const CustomOption = ({ innerProps, isDisabled, label, ...props }) => {

    const history = useHistory();

    return (
        !isDisabled ? (
            props.data.dataType ? (
                <div {...innerProps} className='custom-tag-container searchbar' onClick={() => history.push('/admin/' + props.data.dataType + 's/' + props.data.id)}>
                    <CustomTag value={props.data.dataType} className=" margin-right" />
                    <span className='custom-tag'>{ props.data.firstName + ' ' + props.data.lastName }</span>
                </div>
            ) : (
                <div {...innerProps} className={'custom-tag-container ' + props.data.className} >
                    <span className='custom-tag'>{ label }</span>
                </div>
            )
        ) : null
    );
}

export default CustomOption;
