import React, { useContext, useState } from "react";
import Loader from "react-loader-spinner";
import AuthContext from "../../../contexts/AuthContext";
import axios from "axios";
import {toast} from "react-toastify";

const Settings = () => {

    const { token } = useContext(AuthContext);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [settings, setSettings] = useState({
        max_customers_attendance: 0,
        max_guests_attendance: 0
    });

    React.useEffect(() => {
        axios.get(process.env.REACT_APP_UPA_API_HOST + 'stadium/settings', { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                if(response.data) {
                    console.log(response.data);
                    setSettings({
                        max_customers_attendance: response.data.find(row => row.name === 'max_customers_attendance').value,
                        max_guests_attendance: response.data.find(row => row.name === 'max_guests_attendance').value
                    });
                } else {
                    toast.error('Une erreur est survenue', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [token]);

    const handleSaveSettings = () => {
        console.log(settings);
        axios.post(process.env.REACT_APP_UPA_API_HOST + 'stadium/settings', settings,{ headers:{ "Authorization": "Bearer " + token }})
            .then((response) => {
                console.log(response);
                toast.success("Paramètres sauvegardés", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <div>
            <div className='list-header settings'>
                <div className='header-title flex-title settings'>
                    <h1>Paramètres</h1>
                </div>
                <div className='view-buttons'>
                    <button className='main-button blue-button validate margin-left' disabled={buttonLoading} onClick={handleSaveSettings}>{ buttonLoading ? <Loader type="Oval" color="#FFFFFF" height={30} width={20} /> : 'Sauvegarder' }</button>
                </div>
            </div>
            <div className='input-group without-top'>
                <div className='input-row-container'>
                    <span className="input-label user">FMI Public</span>
                    <div className='input-container without-icon'>
                        <input type='number' min={1} value={settings.max_customers_attendance} className='classic-input' placeholder='Entrez un nombre' onChange={(e) => {
                            setSettings(() => { return { ...settings, max_customers_attendance:e.target.value }})
                        }}/>
                    </div>
                </div>
                <div className='input-row-container'>
                    <span className="input-label user">FMI Invités</span>
                    <div className='input-container without-icon'>
                        <input type='number' min={1} value={settings.max_guests_attendance} className='classic-input' placeholder='Entrez un nombre' onChange={(e) => {
                            setSettings(() => { return { ...settings, max_guests_attendance:e.target.value }})
                        }}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Settings;