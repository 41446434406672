const constants = {}

constants.roles = [
    { value: 'scanner', label: 'Scanner' },
    { value: 'admin', label: 'Administrateur' }
];

constants.users = [
    { value: 'customer', label: 'Public' },
    { value: 'guest', label: 'Invité' }
]

constants.status = [
    { value: 'active', label: 'Actif' },
    { value: 'inactive', label: 'Inactif' },
    { value: 'archived', label: 'Archivé' },
    { value: 'progress', label: 'En cours' },
    { value: 'archived', label: 'Archivé' }
];

constants.frequencies = [
    { value: 'days', label: 'jours' },
    { value: 'weeks', label: 'semaines' },
    { value: 'months', label: 'mois' }
];

constants.weekDays = [
    { value: 'monday', label: 'lundi' },
    { value: 'tuesday', label: 'mardi' },
    { value: 'wednesday', label: 'mercredi' },
    { value: 'thursday', label: 'jeudi' },
    { value: 'friday', label: 'vendredi' },
    { value: 'saturday', label: 'samedi' },
    { value: 'sunday', label: 'dimanche' }
];

constants.booleans = [
    { value: true, label: 'Oui' },
    { value: false, label: 'Non' }
];

constants.all = constants.roles.concat(constants.status);
constants.all = constants.all.concat(constants.frequencies);
constants.all = constants.all.concat(constants.weekDays);
constants.all = constants.all.concat(constants.booleans);
constants.all = constants.all.concat(constants.users);

export default constants;