import React, {useContext, useState} from "react";
import axios from "axios";
import AuthContext from "../../contexts/AuthContext";
import Select from "react-select";
import CustomOption from "./CustomOption";

const CustomSearchInput = ({ placeholder, entity, isMultiSearch, labelType, onChange, className, value }) => {

    const [items, setItems] = useState([]);
    const { token } = useContext(AuthContext);

    React.useEffect(() => {
        console.log('value', value);
        if(typeof entity === "string") {
            axios.get(process.env.REACT_APP_UPA_API_HOST + entity, { headers: { Authorization: 'Bearer ' + token }})
                .then((response) => {
                    console.log(response.data);
                    setItems(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            // In this case, entity MUST be an array
            setItems(entity);
        }
    }, [entity, token, value]);

    React.useEffect(() => {
        if(items) {
            items.map((item) => {
                item.value = item.id;
                switch (labelType) {
                    case 'user':
                        item.value = item.id ? item.id : item.lastName;
                        item.label = item.firstName + ' ' + item.lastName;
                        break;
                    case 'address':
                        item.label = item.address;
                        item.value = item.id;
                        break;
                    default:
                        item.label = item.name;
                        break;
                }
                return item;
            })
        }
    }, [items, labelType]);

    return (
        <Select
            isMulti={isMultiSearch}
            name="colors"
            isRequired={true}
            options={items}
            onChange={onChange}
            value={value}
            // menuIsOpen={true}
            className={ isMultiSearch ? "basic-multi-select multi-search-input " : "basic-single single-search-input " + className}
            classNamePrefix="select"
            placeholder={placeholder}
            components={{ Option: CustomOption }}
        />
    )

}

export default CustomSearchInput;