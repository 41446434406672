import React, {useContext, useState} from 'react';
import { useHistory } from "react-router-dom";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import Switch from "react-switch";
import Loader from "react-loader-spinner";
import Select from "react-select";
import constants from "../../../assets/constants/constants";
import CustomOption from "../../custom/CustomOption";
import md5 from "md5";

const UserAdd = () => {

    const [user, setUser] = useState({
        firstName: '',
        lastName: '',
        email: '',
        role: 'designer',
        status: 'active',
        password: ''
    });
    const [buttonLoading, setButtonLoading] = useState(false);
    const { token } = useContext(AuthContext);
    const history = useHistory();
    const { register, handleSubmit, formState: { errors } } = useForm();

    React.useEffect(() => {
        console.log(user);
    }, [user]);

    const handleCreate = () => {
        setButtonLoading(true);
        axios.post(process.env.REACT_APP_UPA_API_HOST + 'users' , { user: {...user, password: md5(user.password) }}, { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                console.log(response);
                setButtonLoading(false);
                toast.success("L'utilisateur a bien été ajouté", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                history.goBack();
            })
            .catch((error) => {
                toast.error(error.message, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setButtonLoading(false);
            });
    }

    return (
        <div>
            <div className='list-header'>
                <div className='view-title'>
                    <h1>Créer un utilisateur</h1>
                </div>
                <div className='view-buttons'>
                    <button onClick={history.goBack} className='main-button without-icon warning-button'>Annuler</button>
                    <button className='main-button blue-button plus margin-left' onClick={handleSubmit(handleCreate)} disabled={buttonLoading}>{ buttonLoading ? <Loader type="Oval" color="#FFFFFF" height={30} width={20} /> : 'Créer' }</button>
                </div>
            </div>
            <div className='input-group without-top'>
                <div className='input-row-container'>
                    <span className="input-label user">Actif</span>
                    <div className='input-switcher'>
                        <Switch onChange={() => { user.status === 'active' ? setUser({...user, status: 'inactive'}) : setUser({...user, status: 'active'}) }}
                                checked={user.status === "active"}
                                onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={20}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={15}
                                width={35}/>
                    </div>
                </div>
                <div className='input-row-container'>
                    <span className="input-label user">Prénom <span className='required'>*</span></span>
                    <div className='input-container without-icon'>
                        <input value={user.firstName} className={'classic-input ' + (errors.firstName ? 'input-required' : '')} placeholder='Entrez un prénom' {...register("firstName", { required: true })} onChange={(e) => {
                            setUser((user) => { return { ...user, firstName:e.target.value }})
                        }}/>
                    </div>
                </div>
                <div className='input-row-container'>
                    <span className="input-label user">Nom <span className='required'>*</span></span>
                    <div className='input-container without-icon'>
                        <input value={user.lastName} className={'classic-input ' + (errors.lastName ? 'input-required' : '')} placeholder='Entrez un nom' {...register("lastName", { required: true })} onChange={(e) => {
                            setUser((user) => { return { ...user, lastName:e.target.value }})
                        }}/>
                    </div>
                </div>
                <div className='input-row-container'>
                    <span className="input-label email">Email <span className='required'>*</span></span>
                    <div className='input-container without-icon'>
                        <input value={user.email} className={'classic-input ' + (errors.email ? 'input-required' : '')} placeholder='Entrez une adresse email' {...register("email", { required: true })} onChange={(e) => {
                            setUser((user) => { return { ...user, email:e.target.value }})
                        }}/>
                    </div>
                </div>
                <div className='input-row-container'>
                    <span className="input-label email">Mot de passe <span className='required'>*</span></span>
                    <div className='input-container without-icon'>
                        <input type='password' value={user.password} className={'classic-input ' + (errors.password ? 'input-required' : '')} placeholder='Entrez un mot de passe' {...register("password", { required: true })} onChange={(e) => {
                            setUser((user) => { return { ...user, password: e.target.value }})
                        }}/>
                    </div>
                </div>
                <div className='input-row-container'>
                    <span className='input-label role'>Rôle</span>
                    <div className='input-container'>
                        <Select
                            isMulti={false}
                            name="colors"
                            options={constants.roles}
                            className={"basic-single single-search-input"}
                            classNamePrefix="select"
                            defaultValue={constants.roles.find((e) => { return e.value === user.role })}
                            placeholder="Rechercher un rôle"
                            onChange={(newValue) => { setUser((user) => { return { ...user, role:newValue.value }})}}
                            components={{ Option: CustomOption }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserAdd;