import React from "react";
import CustomSearchInput from "./CustomSearchInput";

const CustomSearchBar = () => {

    return (
        <div className='searchbar-item'>
            <div className='input-container'>
                <CustomSearchInput placeholder='Rechercher'
                                   entity='data/searchbar'
                                   isMultiSearch={true}
                                   labelType='user'
                />
            </div>
        </div>
    );
}

export default CustomSearchBar;