import React, {useContext, useState} from 'react';
import { useHistory } from "react-router-dom";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import Switch from "@mui/material/Switch";

const GuestAdd = () => {

    const [guest, setGuest] = useState({ firstName: '', lastName: '', email: null, structure: '', ticketsNumber: 1, sendEmail: true });
    const [buttonLoading, setButtonLoading] = useState(false);
    const { token } = useContext(AuthContext);
    const history = useHistory();

    React.useEffect(() => {
        console.log(guest);
    }, [guest]);

    const handleCreate = () => {
        setButtonLoading(true);
        axios.post(process.env.REACT_APP_UPA_API_HOST + 'guests' , guest, { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                console.log(response);
                setButtonLoading(false);
                toast.success("L'invité a bien été ajouté", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                history.goBack();
            })
            .catch((error) => {
                toast.error(error.response.data.message, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setButtonLoading(false);
            });
    }

    return (
        <div>
            <div className='list-header'>
                <div className='view-title'>
                    <h1>Ajouter un invité</h1>
                </div>
                <div className='view-buttons'>
                    <button className='main-button blue-button margin-right' onClick={handleCreate} disabled={buttonLoading}>{ buttonLoading ? <Loader type="Oval" color="#FFFFFF" height={30} width={20} /> : 'Ajouter' }</button>
                    <button onClick={history.goBack} className='main-button without-icon warning-button'>Annuler</button>
                </div>
            </div>
            <div className='input-group without-top'>
                <div className='input-row-container'>
                    <span className="input-label user">Nom</span>
                    <div className='input-container without-icon'>
                        <input value={guest.firstName} className="classic-input margin-right" placeholder='Prénom' onChange={(e) => {
                            setGuest({ ...guest, firstName:e.target.value })
                        }}/>
                        <input value={guest.lastName} className="classic-input" placeholder='Nom' onChange={(e) => {
                            setGuest({ ...guest, lastName:e.target.value })
                        }}/>
                    </div>
                </div>
                <div className='input-row-container'>
                    <span className="input-label email">Email</span>
                    <div className='input-container without-icon'>
                        <input value={guest.email} className={'classic-input'} placeholder='Entrez un email' onChange={(e) => {
                            setGuest({ ...guest, email:e.target.value })
                        }}/>
                    </div>
                </div>
                <div className='input-row-container'>
                    <span className="input-label location">Organisation</span>
                    <div className='input-container without-icon'>
                        <input value={guest.structure} className={'classic-input'} placeholder='Entrez une organisation' onChange={(e) => {
                            setGuest({ ...guest, structure:e.target.value })
                        }}/>
                    </div>
                </div>
                <div className='input-row-container'>
                    <span className="input-label user">Nombre de billets</span>
                    <div className='input-container without-icon'>
                        <input type='number' min={0} value={guest.ticketsNumber} className={'classic-input'} placeholder='Entrez un nombre de billets' onChange={(e) => {
                            setGuest({ ...guest, ticketsNumber:e.target.value })
                        }}/>
                    </div>
                </div>
                <div className='input-row-container'>
                    <span className="input-label email">Envoyer un email ?</span>
                    <Switch checked={guest.sendEmail} onChange={(e) => setGuest({...guest, sendEmail: e.target.checked })} />
                </div>
            </div>
        </div>
    )
}

export default GuestAdd;