import React, { useContext, useState } from 'react';
import { Link, useHistory, useParams, useRouteMatch } from "react-router-dom";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import CustomTag from "../../custom/CustomTag";
import Tippy from "@tippyjs/react";

const UserView = () => {

    const { userId } = useParams();
    const [user, setUser] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const { token } = useContext(AuthContext);
    const history = useHistory();
    let { url } = useRouteMatch();
    const [popoverVisible, setPopoverVisible] = useState(false);
    const show = () => setPopoverVisible(true);
    const hide = () => setPopoverVisible(false);

    React.useEffect(() => {
        axios.get(process.env.REACT_APP_UPA_API_HOST + 'users/' + userId, { headers: { Authorization: 'Bearer ' + token }})
            .then((response) => {
                if(response.data) {
                    console.log(response.data);
                    setUser(response.data);
                    setIsLoaded(true);
                } else {
                    toast.error('Utilisateur inconnu', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    history.push('/admin/settings');
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [history, token, userId]);

    const handleArchive = () => {
        if(user && user.status === "archived") {
            toast.promise(
                axios.put(process.env.REACT_APP_UPA_API_HOST + 'users/' + userId, { user: {...user, status:"active" }}, { headers: { Authorization: 'Bearer ' + token }}),
                {
                    pending: 'Archivage en cours',
                    success: 'Utilisateur rétabli',
                    error: 'Une erreur est survenue'
                }, {
                    position: "bottom-right",
                    autoClose: 5000,
                }
            ).then(() => {
                setUser({...user, status:"active" });
            });
        } else {
            toast.promise(
                axios.put(process.env.REACT_APP_UPA_API_HOST + 'users/' + userId, { user: {...user, status:"archived" }}, { headers: { Authorization: 'Bearer ' + token }}),
                {
                    pending: 'Archivage en cours',
                    success: 'Utilisateur archivé',
                    error: 'Une erreur est survenue'
                }, {
                    position: "bottom-right",
                    autoClose: 5000,
                }
            ).then(() => {
                setUser({...user, status:"archived" });
            });
        }
    }

    const handleDelete = () => {
        if (window.confirm("Attention : toutes les données de cet utilisateur seront perdues.")) {
            toast.promise(
                axios.delete(process.env.REACT_APP_UPA_API_HOST + 'users/' + userId, { headers: { Authorization: 'Bearer ' + token }}),
                {
                    pending: 'Suppression en cours',
                    success: 'Utilisateur supprimé',
                    error: 'Une erreur est survenue'
                }, {
                    position: "bottom-right",
                    autoClose: 5000,
                }
            ).then(() => {
                history.goBack();
            });
        }
    }

    const popover = (
        <div className='user-options' width={150} onClick={popoverVisible ? hide : show}>
            <div className='user-options-item archive' onClick={handleArchive}>
                <span>{user && user.status === "archived" ? 'Rétablir' : 'Archiver'}</span>
            </div>
            <div className='user-options-item delete' onClick={handleDelete}>
                <span>Supprimer</span>
            </div>
        </div>
    );

    return isLoaded ? (
        <div className='view-container'>
            <div className='view-header'>
                <div className='view-title'>
                    <h1>{user.firstName + ' ' + user.lastName}</h1>
                    <span className='view-id'>{user.id}</span>
                </div>
                <div className='view-buttons'>
                    { user.status === "archived" ? null :
                        <Link to={ url + '/edit' }>
                            <button className='main-button blue-button edit'>Modifier</button>
                        </Link>
                    }
                    <Tippy content={popover}
                           allowHTML={true}
                           placement={'bottom-end'}
                           offset={[0,5]}
                           visible={popoverVisible}
                           onClickOutside={hide}
                           interactive={true}
                           appendTo={'parent'}>
                        <button className='squared-button margin-left' onClick={popoverVisible ? hide : show} />
                    </Tippy>
                </div>
            </div>
            <div className='input-row-container'>
                <span className="input-label user">Statut</span>
                <CustomTag value={user.status} />
            </div>
            <div className='input-row-container'>
                <span className="input-label email">Email</span>
                <CustomTag value={user.email} />
            </div>
            <div className='input-row-container'>
                <span className="input-label role">Rôle</span>
                <CustomTag value={user.role} />
            </div>
        </div>
    ) : (<div className='loader-container'><Loader type="Oval" color="#2C95E8" height={30} width={30} /></div>)
}

export default UserView;