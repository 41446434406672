import {Redirect, Route} from "react-router-dom";
import {useContext} from "react";
import AuthContext from "../../contexts/AuthContext";

const PrivateRoute = ({ children, ...rest }) => {

    const {token} = useContext(AuthContext);

    return (
        <Route {...rest} render={({location}) => {
            return token ? children : <Redirect to={{
                pathname: '/login',
                state: { from: location }
            }} />
        }} />
    );
}

export default PrivateRoute;