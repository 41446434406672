import React from 'react';
import './index.css';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import { render } from 'react-dom';
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";
import 'dayjs/locale/fr';
import dayjs from "dayjs";

dayjs().locale('fr');

const container = document.getElementById('root');

render(
  <React.StrictMode>
      <BrowserRouter>
          <ToastContainer limit={4} />
          <App />
      </BrowserRouter>
  </React.StrictMode>, container
);
