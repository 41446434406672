import md5 from "md5";
import React, {useContext, useState} from 'react';
import axios from 'axios';
import "../../assets/styles/Login.css";
import AuthContext from "../../contexts/AuthContext";
import {Button, Form} from "react-bootstrap";
import full_logo from "../../assets/images/small-logo.png";
import {Link, Redirect, useLocation} from "react-router-dom";
import Loader from "react-loader-spinner";

const Login = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [loader, setLoader] = useState(false);
    const [redirectToReferrer, setRedirectToReferrer] = useState(false);
    const {state} = useLocation();
    const {user, setToken} = useContext(AuthContext);

    if (redirectToReferrer === true || user) {
        return <Redirect to={ state?.from || '/admin'}/>
    }

    const handleSubmit = (event) => {
        if(event.type === 'click' || event.key === 'Enter') {
            setLoader(true);

            axios.post(process.env.REACT_APP_UPA_API_HOST + "login", {
                email: email,
                password: md5(password)
            })
                .then((response) => {
                    setLoader(false);
                    localStorage.setItem('session_token', response.data.token);
                    setToken(response.data.token);
                    setRedirectToReferrer(true);
                })
                .catch((error) => {
                    setError(error.response.data.message);
                    setLoader(false);
                });
        }
    }

    return (
        <div className="main-login">
            <div className="login-background"/>
            <div className="login-container">
                <div className="login-form">
                    <div className='img-container'>
                        <img src={full_logo} alt="logo" width="300"/>
                    </div>
                    <div>
                        <h1>Connexion</h1>
                        {error ? <p className="login-error">{error}</p> : null}
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Email</Form.Label>
                            <span className='required'> *</span>
                            <Form.Control type="email" placeholder='Saisissez votre adresse email' value={email}
                                          onChange={(e) => {
                                              setEmail(e.target.value)
                                              setError('')
                                          }}
                                          onKeyUp={(e) => handleSubmit(e)}
                            />
                        </Form.Group>
                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>Mot de passe</Form.Label>
                            <span className='required'> *</span>
                            <Form.Control type="password" placeholder='Saisissez votre mot de passe' value={password}
                                          onChange={(e) => {
                                              setPassword(e.target.value)
                                              setError('')
                                          }}
                                          onKeyUp={(e) => handleSubmit(e)}
                            />
                        </Form.Group>
                        <Link to='/forgot-password' className='forgot-password'>
                            <p>Mot de passe oublié ?</p>
                        </Link>
                        <div className='login-button-container'>
                            {loader ?
                                <Button className="login-button-loading" disabled><Loader type="Oval" color="#ffffff"
                                                                                          height={25}
                                                                                          width={20}/></Button> :
                                <Button className="login-button" type="submit" onClick={handleSubmit}>Se
                                    connecter</Button>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
