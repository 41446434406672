import React, {useContext, useState} from "react";
import {Link, useRouteMatch} from "react-router-dom";
import CustomList from "../../custom/CustomList";
import {toast} from "react-toastify";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import CustomTag from "../../custom/CustomTag";
import CustomModal from "../../custom/CustomModal";
import GuestImporter from "./GuestImporter";
import dayjs from "dayjs";
import Loader from "react-loader-spinner";
import JSZip from "jszip";
import saveAs from "file-saver";

const GuestList = () => {

    let { path } = useRouteMatch();
    const [selectedColumns, setSelectedColumns] = useState(0);
    const [selectedIds, setSelectedIds] = useState([]);
    const [refreshData, setRefreshData] = useState(0);
    const [data, setData] = useState(null);
    const [open, setOpen] = React.useState(false);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const handleOpen = () => setOpen(true);
    const { token } = useContext(AuthContext);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Prénom',
                accessor: 'firstName',
                icon: 'user'
            },
            {
                Header: 'Nom',
                accessor: 'lastName',
                icon: 'user'
            },
            {
                Header: 'Email',
                accessor: 'email',
                icon: 'email'
            },
            {
                Header: 'Organisation',
                accessor: 'structure',
                icon: 'order',
                Cell: ({ cell: { value } }) => (<CustomTag value={value} />)
            },
            {
                Header: 'Email envoyé',
                accessor: 'emailSent',
                icon: 'email',
                Cell: ({ cell: { value } }) => (<CustomTag value={value ? dayjs(value).format('DD MMMM YYYY à HH:mm') : false} />)
            },
            {
                Header: 'Confirmé',
                accessor: 'confirmed',
                icon: 'id',
                Cell: ({ cell: { value } }) => (<CustomTag value={value} />)
            }
        ],
        []
    )

    const handleBulkDelete = () => {
        if (window.confirm("Attention : vous êtes sur le point de supprimer plusieurs invités")) {
            toast.promise(
                axios.post(process.env.REACT_APP_UPA_API_HOST + 'guests/bulk-delete', { selectedIds }, { headers: { Authorization: 'Bearer ' + token }}),
                {
                    pending: 'Suppression en cours',
                    success: 'Invités supprimés',
                    error: 'Une erreur est survenue'
                }, {
                    position: "bottom-right",
                    autoClose: 5000,
                }
            ).then(() => {
                setRefreshData(refreshData + 1);
            });
        }
    }

    const handleBulkDownload = async () => {
        console.log(selectedIds);
        const zip = new JSZip();
        let occurrence = 1;
        setDownloadLoading(true);
        for (const guestId of selectedIds) {
            let blob = await fetch(process.env.REACT_APP_UPA_INTERFACE_HOST + 'api/files/' + guestId + '.pdf').then(r => r.blob());
            zip.file('Billet_' + occurrence + '.pdf', blob);
            occurrence++;
        }
        zip.generateAsync({type:"blob"}).then(function(content) {
            saveAs(content, "billets.zip");
            setDownloadLoading(false);
        });
    }

    return (
        <div>
            <div className='list-header'>
                <div className='header-title flex-title subscriptions'>
                    <h1>Billets invités</h1>
                    <span className='custom-badge margin-left margin-right'>{data ? data.length : (<Loader type="Oval" color="#fff" height={10} width={10} />)}</span>
                    <span className='custom-badge green-badge'>{data ? data.filter(row => row.confirmed).length : (<Loader type="Oval" color="#fff" height={10} width={10} />)}</span>
                </div>
                { selectedColumns === 0 ?
                    <div className='header-buttons'>
                        <Link to={path + '/new'}>
                            <button className='main-button blue-button plus'>Ajouter</button>
                        </Link>
                        <button className='main-button download' onClick={handleOpen}>Importer</button>
                        <CustomModal show={open} setShow={setOpen} title="Importer des invités">
                            <GuestImporter setOpen={setOpen} setRefreshData={setRefreshData} refreshData={refreshData}/>
                        </CustomModal>
                    </div>
                    :
                    <div className='header-buttons'>
                        <button className='main-button download' onClick={handleBulkDownload}>{ !downloadLoading ? 'Télécharger' : <Loader type="Oval" color="#2C95E8" height={22} width={22} /> }</button>
                        <button className='main-button warning-button delete' onClick={handleBulkDelete}>Supprimer</button>
                    </div>
                }
            </div>
            <CustomList entity='guests' columns={columns} setSelectedColumns={setSelectedColumns} setSelectedIds={setSelectedIds} refreshData={refreshData} setData={setData} />
        </div>
    );
}

export default GuestList;
